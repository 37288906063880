
  import { Component, Vue } from "vue-property-decorator";
  import LzBox from "@/components/Box.vue";
  import { namespace } from "vuex-class";
  import Chart, { ChartConfiguration } from "chart.js/auto";
  import apiData from "../api";
  import VueI18n from "vue-i18n";
  const auth = namespace("auth");

  Chart.register();

  @Component({ components: { LzBox } })
  export default class Read extends Vue {
    @auth.State("id")
    public ongId!: string;

    data: DashboardData = {
      articles: 0,
      earnings: 0,
      earningsByEntityType: {
        Event: 0,
        Product: 0,
        Project: 0,
        Service: 0
      },
      earningsByMonth: {},
      events: 0,
      portfolio: 0,
      reservations: 0,
      services: 0,
      reviews: 0
    };
    analyticsLink = "";

    currentMonth = 0;

    donutPeriodOptions = {
      last24Hours: "Last 24 hours",
      today: "Today",
      yesterday: "Yesterday",
      last7Days: "Last 7 days",
      last30Days: "Last 30 days",
      last60Days: "Last 60 days",
      last90Days: "Last 90 days",
      last180Days: "Last 180 days"
    };

    async mounted() {
      await apiData.getDashboard(this.ongId).then(data => {
        this.data = { ...this.data, ...data };
      });

      // incomes bar chart
      const barCtx = (document.getElementById(
        "incomesBar"
      ) as HTMLCanvasElement)?.getContext("2d");

      if (!barCtx) return;

      const incomeLabels = Object.keys(this.data.earningsByMonth);
      const incomeData = Object.values(this.data.earningsByMonth);

      new Chart(barCtx, {
        type: "bar",
        legend: {
          display: false
        },
        data: {
          labels: incomeLabels,
          title: null,
          datasets: [
            {
              data: incomeData,
              backgroundColor: ["#8D00D8"],
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      } as ChartConfiguration);

      const ctxIncomesDonut = (document.getElementById(
        "incomesDonut"
      ) as HTMLCanvasElement)?.getContext("2d");

      if (!ctxIncomesDonut) return;

      const doughnutLabelsMap: Record<EntityType, VueI18n.TranslateResult> = {
        Event: this.$t("data.read.labels.events"),
        Product: this.$t("data.read.labels.shop"),
        Project: this.$t("data.read.labels.projects"),
        Service: this.$t("data.read.labels.services")
      };

      const [doughnutLabels, doughnutData] = Object.entries(
        this.data.earningsByEntityType
      ).reduce(
        ([labels, data], [key, value]) => {
          labels.push(doughnutLabelsMap[key as EntityType]);
          data.push(value);
          return [labels, data];
        },
        [[], []] as [VueI18n.TranslateResult[], number[]]
      );

      const colorsMap: Record<EntityType, string> = {
        Event: "#0093A7",
        Product: "#8D00D8",
        Project: "#0CF2B4",
        Service: "#EB2873"
      };
      const colors = Object.keys(this.data.earningsByEntityType).map(
        key => colorsMap[key as EntityType]
      );

      new Chart(ctxIncomesDonut, {
        type: "doughnut",
        data: {
          labels: doughnutLabels,
          datasets: [
            {
              data: doughnutData,
              backgroundColor: colors,
              borderColor: colors,
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            arc: {
              borderJoinStyle: "round"
            }
          },
          plugins: {
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
                padding: 20,
                font: {
                  size: 12
                }
              }
            },
            paddingBelowLegends: false
          }
        }
      } as ChartConfiguration);
    }
  }
